import React from "react";
import './App.scss';
import {useRef} from "react";

function App() {
    const [isClicked, setIsClicked] = React.useState(false)
    const audioRef = useRef(null)
    const playSound = () => {
        setIsClicked(true)
        const audio = audioRef.current;
        audio.currentTime = 0;
        setTimeout(() =>
            setIsClicked(false), 300
        )

        audio.play()
    }
    return (

        <div className="App">

            <audio ref={audioRef}>
                <source src='/nope.mp3' type='audio/mp3'/>
            </audio>

            <div className='Header'><h1>$NOPE.</h1></div>
            <div className="FirstPage">
                <div className='FPC'>
                    <img className='star' src='/img_16.png'/>
                    <img className='catttt' src='/img_17.png'/>
                    <div className='FirstPageBox'>
                        <h1>Nope Cat</h1>
                        <div className='FirstPageBoxIcons'>
                            <a href='https://dexscreener.com/solana/AEAR2SYMMdFY8DnC8VHLvF9G94e5pN9PJgfSUpRGpump'><img src='/img_20.png'/></a>
                            <a href='https://pump.fun/AEAR2SYMMdFY8DnC8VHLvF9G94e5pN9PJgfSUpRGpump'><img src='/img_1.png'/></a>
                            <a href='https://t.me/nopesol'><img src='/img_21.png'/></a>
                        </div>
                        <div>
                            <div className='FUCK'><img onClick={() => playSound()}
                                                       src={isClicked ? 'img_31.png' : 'img_30.png'}/><img className='AF'
                                                                                                          src=' img_4.png'/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="items-wrap">
                <div className="items marquee">
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className="H">$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className="H">$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>

                </div>
                <div aria-hidden="true" className="items marquee">
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className="H">$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className="H">$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>

                </div>
            </div>
            <div className='NewSecondPage'>
                <div className='NewSecondPageContainer '>
                    <h1>Why $NOPE?</h1>
                    <p> Because Normal is Overrated!</p>
                    <div className='Graf'>
                        <div className='G1'><p>Token Address: AEAR2SYMMdFY8DnC8VHLvF9G94e5pN9PJgfSUpRGpump</p></div>
                        <div className='G2'><p>Token supply: 1,000,000,000 </p></div>
                        <div className='G3'><p>Burned 100%</p></div>
                    </div>


                </div>
                <div className='NewSecondPageSecondContainer'>

                    <img className='star2' src='img_18.png'/>

                    <img className='starcat' src='img_19.png'/>

                </div>


            </div>
            <div className='VideoPage'>

                   <img className='background-video' src='vecteezy_forex-trading-investor-financial-analyst-stock-market-chart_26389055 (1).gif'/>


                <img src="img_25.png" alt="Overlay" className="overlay-image" />
                <div className='VPC'>
                    <img src='img_22.png'/>
                    <div className='VPCC'>
                        <h1>Say $NOPE to Boring Investments</h1>
                        <p>Don't upset the cat, he's already grumpy</p>


                            <a href='https://pump.fun/AEAR2SYMMdFY8DnC8VHLvF9G94e5pN9PJgfSUpRGpump'>
                                <button>
                                <h1>BUY!!!</h1>
                                </button>
                            </a>


                    </div>
                </div>


            </div>
<div className='Drochevo'>
<div className='About'>About</div>


<div className='HugeText'>
    <p>Welcome to the world of $NOPE, where cute memes meet finance in the most grump-tastic way possible! Inspired by the legendary Grumpy Cat, $NOPE is not just another cryptocurrency — it’s a movement. We believe in embracing the grumpy side of life and bringing joy through hilarious memes and innovative blockchain technology.</p>
    <p>Grumpy Approved: Every $NOPE transaction carries the grumpy seal of approval. Because normal is boring, and grumpy is awesome!</p>

</div>
    <img className='Paw1' src='img_24.png'/>
    <img className='Paw2' src='img_23.png'/>
</div>
<div className='Drochevo2'>
<div className='DL'>
<div className='RM'>ROADMAP</div>
    <div className='BBB'>
<img src='img_28.png'/>


    <a href='https://pump.fun/AEAR2SYMMdFY8DnC8VHLvF9G94e5pN9PJgfSUpRGpump'>
        <button>     <p>BUY $NOPE</p>
        </button>
    </a>



    </div>
</div>
<div className='DM'> <img className='Paw3' src='img_26.png'/>

<div className='Block'><h1>Launch and Grumpify </h1>
    <p>Kickstart the $NOPE revolution with a bang! </p></div>
    <div className='Block'><h1>Grumpy Adoption</h1>
        <p>Make $NOPE a household name among meme enthusiasts and crypto investors.</p></div>
</div>
    <div className='DR'>   <img className='Paw4' src='img_27.png'/><div className='Block'><h1>Grumpy Growth </h1>
        <p>Expand $NOPE's presence and influence in the crypto world.</p></div>
        <div className='Block'><h1>Grumpy Domination</h1>
            <p>Establish $NOPE as the leading meme-inspired cryptocurrency. </p></div>

    </div>


</div>

            <div className="items-wrap">
                <div className="items marquee">
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className="H">$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className="H">$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>

                </div>
                <div aria-hidden="true" className="items marquee">
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className="H">$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className="H">$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>
                    <h1 className='H'>$NOPE</h1>

                </div>
            </div>




            <div className='FourthPage'>
                <div className='FourthPageBox'>
                    <div className='FourthPageBoxC'>

                        <a href='https://dexscreener.com/solana/AEAR2SYMMdFY8DnC8VHLvF9G94e5pN9PJgfSUpRGpump'>
                    <div className='FourthPageBoxItem'>
                        <img src='img_20.png'/><h1>Buy on DEX</h1>

                    </div>
                        </a>
                        <a href='https://pump.fun/AEAR2SYMMdFY8DnC8VHLvF9G94e5pN9PJgfSUpRGpump'>
                    <div className='FourthPageBoxItem'>
                        <img src='img_1.png '/><h1>Buy on PUMP</h1>

                    </div>
                        </a>
                        <a href='https://t.me/nopesol'>
                    <div className='FourthPageBoxItem'>
                       <img src='/img_21.png'/><h1>Community</h1>

                    </div>
                        </a>
                    </div>
                    <img className='Caca' src='img_29.png'/>
                </div>


            </div>
            <div className='Footer'>
                <h1>$NOPE 2024</h1>
                <p>inspired by grumpy cat</p>
            </div>

        </div>
    );
}

export default App;
